import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { Delete, Edit, PersonAdd, PrintRounded } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { IconButton, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const validationSchema = yup.object({
    premiumId: yup
        .string('Enter your username')
        .required('policy name is required'),

    customerId: yup
        .string('this is a string')
        .required('customer is required'),
    amount: yup
        .number('A number is required')
        .required('Amount is require'),
    paymentDate: yup
        .string('Select policy class')
        .required('Validity period is required'),




});


const Payment = () => {


    const [showModal, setShowModal] = useState(false);
    const [customers, setCustomers] = useState([])
    const [customerId, setCustomerId] = useState('');
    const [quotationAmount, setQuotationAmount] = useState(0)
    const [custPremium, setCustPremium] = useState([])
    const [data, setData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const navigate=useNavigate()



    // METHODES

    useEffect(() => {


        getAllCustomers();
        getAllPayments()

    }, [])

    useEffect(() => {
        getPremiumByCustomer()
    }, [customerId])
    const setNewPremiumForm = () => {
        formik.resetForm();
        setIsUpdate(false)
        setQuotationAmount(0)
        handleShow()
    }
    // FORMIK SET UPS
    const formik = useFormik({
        initialValues: {
            id: null,
            premiumId: '',
            customerId: null,
            amount: null,
            paymentDate: '',

        },

        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            const id = values.id;
            if (id) {
                // console.log(values)
                publicRequest.put('/payment', values)
                    .then(res => {
                        handleClose()
                        getAllPayments()
                        notify('Premium updated Successfully')
                    })
            } else {
                publicRequest.post('/payment/register', values)
                    .then(res => {
                        actions.resetForm()
                        handleClose()
                        getAllPayments()
                        notify('Premium created Successfully')

                    })
            }

        }
    })
    // Load Business Class

    // Load Customers
    const getAllCustomers = () => {
        publicRequest.get('customers')
            .then(res => setCustomers(res.data))
    }


    const getAllPayments = () => {
        publicRequest.get('payment')
            .then(res => setData(res.data))
            .catch(err => {
                console.log(err)
            })
    }
    const findSelectedPremium = (e) => {
        const selectedPremium = custPremium.filter(item => {

            return item.id === Number(e.target.value)
        })


        setQuotationAmount((parseFloat(selectedPremium[0].amount)).toFixed(2))

        formik.handleChange(e)
    }


    const getPremiumByCustomer = () => {
        publicRequest.get(`premium/customer/${customerId}`)
            .then(res => setCustPremium(res.data))
            .catch(err => {
                console.log(err)
            })
    }

    const handleCustomerChange = (e) => {
        setCustomerId(e.target.value)
        formik.handleChange(e)
    }
    //UPDATE FORM SETUPS

    const setUpdateForm = (data) => {
        formik.setValues(data)
        setIsUpdate(true)
        setShowModal(true)
    }



    // DELETE USERS
    const deletePayment = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/payment/' + row.id)
                    .then(res => {
                        notify('Policy deleted successfully')
                        getAllPayments()
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
      
       

        {
            name: 'Policy Number',
            selector: row => row.premium.policyNumber,
            sortable: true,
        },
        {
            name: 'Premium Quotation',
            selector: row => row.premium.amount,
            sortable: true,
        },
        {
            name: 'Amount Paid',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Current Balance',
            selector: row => row.balance,
            sortable: true,
        },

        {
            name: 'Payment Date',
            selector: row => (moment(row.paymentDate).format("MMM Do , YYYY")),
            sortable: true,
        },

        {
            
            
            cell: (row) => <><IconButton onClick={()=>navigate('/reciept/'+row.id)}><PrintRounded /></IconButton> <IconButton onClick={() => setUpdateForm(row)} ><Edit /></IconButton>
                <IconButton onClick={() => deletePayment(row)}><Delete /></IconButton>

            </>,
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => setNewPremiumForm()}>
                        <PersonAdd /> Add New
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="Payments List"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>


            <Modal show={showModal}
                onHide={handleClose}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdate ? "Update Policy" : "Create New"} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Customer">
                            <Form.Select
                                id="customerId"
                                name="customerId"
                                value={formik.values.customerId}
                                onChange={e => handleCustomerChange(e)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.customerId && Boolean(formik.errors.customerId)}

                            >
                                <option value=''>Select Customer</option>

                                {customers.map(customer =>
                                    (<option value={Number(customer.id)}>{`${customer.surname ? customer.surname : customer.coporationName} ${customer.firstName ? customer.firstName : ''}  -  (ID: ${customer.id})`}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Policy">
                            <Form.Select
                                id="premiumId"
                                name="premiumId"
                                value={formik.values.policyId}
                                onChange={e => findSelectedPremium(e)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.premiumId && Boolean(formik.errors.premiumId)}

                            >
                                <option value=''>Select Policy</option>

                                {custPremium.map(premium =>
                                    (<option value={Number(premium.id)}>{(premium.policy.policyName).toUpperCase()}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>
                        <h4 className='text-center text-bold text-danger' >Premium Quotation: {quotationAmount}</h4>

                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Amount Paid"
                            type="number"
                            id="amount"
                            name="amount"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}

                        />
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label=" Date Of Payment"
                            type="date"
                            id="paymentDate"
                            name="paymentDate"
                            value={formik.values.paymentDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.paymentDate && Boolean(formik.errors.paymentDate)}
                            helperText={formik.touched.paymentDate && formik.errors.paymentDate}

                        />





                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-xs btn-outline-danger' onClick={handleClose}>
                        Close
                    </button>
                    <button type="submit" className='btn btn-xs btn-outline-warning' onClick={formik.handleSubmit} >
                        {isUpdate ? "Update" : "create"}
                    </button>
                </Modal.Footer>
            </Modal>

        </Card>
    )
}

export default Payment
