import { createSlice } from '@reduxjs/toolkit'


const premiumSlice = createSlice(
    {
        name: "premium",
        initialState: {
            currentPremium: {},
            currentDocument:{},
            isFetching: false,
            error: false,
           

        },
        reducers: {
            actionStart: (state) => {
                state.isFetching = true;

            },
            findPremiumSuccess: (state, action) => {
                state.isFetching = false;
                state.currentPremium = action.payload.premium;
                state.currentDocument = action.payload.document;
                state.error = false;
              console.log('payload',action.payload)
            },
            findFailure: (state) => {
                state.isFetching = false;
                state.error = true
            },




        }



    })


export const {
    findPremiumSuccess,
    findFailure,
    actionStart
} = premiumSlice.actions;
export default premiumSlice.reducer;