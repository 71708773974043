
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import { Card } from 'react-bootstrap'


const MyCalendar = () => {
    // const DnDCalendar = withDragAndDrop(Calendar);
    // const localizer = momentLocalizer(moment)
    return (
    
    
        <Card.Body>
      
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={[
                    { title: 'event 1', date: '2021-12-20' },
                    { title: 'event 2', date: '2021-12-30' }
                ]}
              
               
            />
        </Card.Body>
  
    )
}
export default MyCalendar