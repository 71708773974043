
import { Formik, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { publicRequest } from '../../requestMethods';
import * as yup from 'yup'
import FileUpload from './DocumentFileUpload';
import { useDispatch, useSelector } from 'react-redux';
import { findPremium } from '../../redux/premiumApi';
import { List, ListItem, ListItemText, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';






const BusinessClass = ({policyNumberErr}) => {

    let [selectedPremium, setselectedPremium] = useState({})
    const persistedPremium=useSelector(state=>state.premium.currentPremium)
    const dispatch = useDispatch();
    


    useEffect(() => {
        setselectedPremium(persistedPremium);
    }, [])

        
       
       
    console.log(selectedPremium.businessClass)



    return (
        <Container>
            <Row>
                <Col md={6} xs={12}>
                    

                    <hr />
                    {  selectedPremium.id && <div>
                        <h5 className='text-center'>Customer Information</h5>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={(selectedPremium.customer.surname ? `${selectedPremium.customer.surname}  ${selectedPremium.customer.firstName}` : selectedPremium.customer.coporationName)}
                                    secondary="CLIENT NAME"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={selectedPremium.customer.phoneNumber}
                                    secondary="Phone Number"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={selectedPremium.customer.contactAddress ? selectedPremium.customer.contactAddress : selectedPremium.customer.propertyLocation}
                                    secondary="Contact Address"
                                />
                            </ListItem>
                        </List>
                        <h5 className='text-center'>Business Class Information</h5>
                        <hr />
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={selectedPremium.businessClass ? selectedPremium.businessClass.businessType:''}
                                    secondary="CLASS NAME"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={selectedPremium.businessClass ? selectedPremium.businessClass.classNumber:''}
                                    secondary="CLASS NUMBER"
                                />

                            </ListItem>
                        </List>


                    </div>}
                </Col>
                <Col md={6} xs={12}>


                    <h5 className='text-center'>Premium/Policy Information</h5>

                    {selectedPremium&&selectedPremium.id && <List>
                        <ListItem>
                            <ListItemText
                                primary={selectedPremium.policy ? selectedPremium.policy.policyName : ''}
                                secondary="POLICY  TYPE"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={selectedPremium.insuranceCompany.name}
                                secondary="Insurance Company/Underwriter"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`N${(selectedPremium.amount).toFixed(2)}`}
                                secondary="Premium Quotation"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={selectedPremium.premiumPeriod}
                                secondary="Premium Validity Period"
                            />
                        </ListItem>

                    </List>}
                  
                    <ListItem>
                            <ListItemText
                                primary={selectedPremium.policyNumber?selectedPremium.policyNumber:<p className='text-danger'>Policy Number Is Required! <br/>To Continue, go to the premium page and add Policy Number</p>}
                                secondary="Policy Number"
                            />
                        </ListItem>
                    
                  

                </Col>

            </Row>

        </Container>
    );
}
export default BusinessClass
