import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import {  toast } from 'react-toastify';
import { Delete, Edit,  PersonAdd } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { TextField } from '@mui/material';


const validationSchema = yup.object({
    username: yup
        .string('Enter your username')
        .required('username is required'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(6, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),




});


const User = () => {


    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)




    // METHODES

    useEffect(() => {

        getUsers();
    }, [])

    const setNewUserForm = () => {
        formik.resetForm();
        setIsUpdate(false)
        handleShow()
    }
    // FORMIK SET UPS
    const formik = useFormik({
        initialValues: {
            id: null,
            username: '',
            email: '',
            password: '',
            role: "BASIC_USER",
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            const id = values.id;
            if (id) {
                // console.log(values)
                publicRequest.put('/auth', values)
                    .then(res => {
                        handleClose()
                        getUsers()
                        notify('User updated Successfully')
                    })
            } else {
                publicRequest.post('/auth/register', values)
                    .then(res => {
                        actions.resetForm()
                        handleClose()
                        getUsers()
                        notify('User created Successfully')

                    })
            }

        }
    })
    // Load Users
    const getUsers = () => {
        publicRequest.get('auth')
            .then(res => setData(res.data))
    }
    //UPDATE FORM SETUPS

    const setUpdateForm = (data) => {
        formik.setValues(data)
        setIsUpdate(true)
        setShowModal(true)
    }



    // DELETE USERS
    const deleteUser = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/auth/' + row.id)
                    .then(res => {
                        notify('User deleted successfully')
                        getUsers()
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'USername',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Email Address',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'Reg Date',
            selector: row => (moment(row.createdAt).format("MMM Do , YYYY")),
            sortable: true,
        },

        {

            button: true,
            cell: (row) => <> <button className='btn btn-xs btn-outline-warning m-1' onClick={() => setUpdateForm(row)} ><Edit /></button>
                <button className='btn  btn-outline-danger' onClick={() => deleteUser(row)}><Delete /></button>

            </>,
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => setNewUserForm()}>
                        <PersonAdd /> Add New
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="User List"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>


            <Modal show={showModal}
                onHide={handleClose}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdate ? "Update User" : "Create New"} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form com onSubmit={formik.handleSubmit}>

                        <FloatingLabel className="mb-3" controlId="floatingSelect" label="User Role">
                            <Form.Select
                                id="role"
                                name="role"
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.role && Boolean(formik.errors.role)}
                                

                            >
                                <option>Select user role</option>
                                <option value="CUSTOMER">CUSTOMER</option>
                                <option value="EMPLOYEE">EMPLOYEE</option>
                                <option value="BROCKER">BROCKER</option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="BASIC_USER">BASIC USER</option>
                            </Form.Select>

                        </FloatingLabel>
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Username"
                            type="text"
                            id="username"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}

                        />

                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Email Address"
                            type="email"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.firstName && formik.errors.email}

                        />
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="User Password"
                            type="password"
                            id="email"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}

                        />
                        <TextField variant="outlined"
                            fullWidth
                            label="confirmPassword"
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}

                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger' onClick={handleClose}>
                        Close
                    </Button>
                    <Button className='btn-success' onClick={formik.handleSubmit}>
                        {isUpdate ? "Update" : "create"}
                    </Button>
                </Modal.Footer>
            </Modal>
           
        </Card>
    )
}

export default User
