import { publicRequest } from "../requestMethods";
import {
    actionFailure, actionStart,
    actionSuccess, registerUser,
    userLogout
} from "./userRedux";

export const login = async (dispatch, userLoginDetails) => {
    dispatch(actionStart());


    try {
        const user = await publicRequest.post('/auth/login', userLoginDetails)
        dispatch(actionSuccess(user.data))
    } catch (err) {
        console.log(err)
        dispatch(actionFailure())
    }

}

export const Register = (dispatch, data) => {
    dispatch(actionStart)
    publicRequest.post('/auth/register', data)
        .then(res => {
            dispatch(registerUser(res.data))
        })
}
export const Logout = (dispatch) => {
    dispatch(userLogout())
}