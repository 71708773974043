import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card } from 'react-bootstrap';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLefttIcon from '@mui/icons-material/ChevronLeft';
import BusinessClass from './underwriters/BusinessClass';
import { publicRequest } from '../requestMethods';
import { useSelector } from 'react-redux';
import PaymentDetails from './underwriters/PaymentDetails';
import UnderwriterDocumention from './underwriters/UnderwriterDocumentation';
import Summary from './underwriters/Summary';

const steps = ['Business Class/Client Details', 'Payment/Charges Details', 'Documentation'];

const UnderwriterProcess = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const[policyNumber, setPolicyNumber]=useState()
    const [policyNumberErr,setPolicyNumberErr]=useState(false)
    const currentPremium=useSelector(state=>state.premium.currentPremium)

console.log(currentPremium)
useEffect(()=>{

    if(currentPremium.policyNumber){
        setPolicyNumberErr(false)
        console.log('current',currentPremium.policyNumber)
    }
        
        else{
            setPolicyNumberErr(true)
           
        }
},[])

console.log(currentPremium)
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        

       // publicRequest.post('underwriter/register')

        
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);


    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {


        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <BusinessClass />;
            case 1:
                return <PaymentDetails currentPremium={currentPremium}/>;
            case 2:
                return <UnderwriterDocumention/>;
            default:
                return "Unknown step";
        }
    }

    return (
        <Card className='shadow'>
            <Card.Header>
                <Card.Title>Underwriting Wizard</Card.Title>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)} disabled={!currentPremium.policyNumber}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Card.Header>
            {allStepsCompleted() ? (
                <>
                   <Summary/>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <button className='btn btn-primary mb-5 me-5 ms-5' onClick={handleReset}>Reset</button>
                    </Box>
                </>
            ) : (
                <>


                    <Card.Body>

                        {getStepContent(activeStep)}
                    </Card.Body>



                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <button className='btn btn-primary mb-5 ms-5'
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            type='button'
                            sx={{ mr: 1 }}
                        >
                           <ChevronLefttIcon/> Prev
                        </button>
                        <Box sx={{ flex: '1 1 auto' }} />



                       
                        {activeStep !== steps.length &&
                            (completed[activeStep] ? (
                               <h3></h3>
                            ) : (
                                    <button className='btn btn-primary mb-5 me-5 ms-5' onClick={handleComplete}
                                    disabled={!currentPremium.policyNumber}>
                                    { completedSteps() === totalSteps() - 1
                                        ? 'Finish'
                                        : 'Next'}
                                        <ChevronRightIcon/>
                                </button>
                            ))}
                    </Box>
                </>
            )}


        </Card >
    );
}
export default UnderwriterProcess