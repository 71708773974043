import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { Delete, Edit, PersonAdd } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { IconButton, TextField } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useNavigate, useParams } from 'react-router-dom';




const DocumentDetails = () => {


    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([])
    const [businessClass, setBusinessClass] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const navigate=useNavigate()
    const {premiumId} =useParams()




    // METHODES

    useEffect(() => {

        getDocuments(premiumId);
      
    }, [])

  console.log(data)
    // FORMIK SET UPS
    
    // Load Users
 

    const getDocuments = (premiumId) => {
        publicRequest.get('documentations/'+premiumId)
            .then(res => setData(res.data))
    }
    //UPDATE FORM SETUPS

   const downdloadDocument=(id)=>{
publicRequest.get('/downdload/'+id)
       .then(res=>{})
   }



    // DELETE USERS
    const deleteDocument = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/documentations/' + row.id)
                    .then(res => {
                        notify('Policy deleted successfully')
                        getDocuments(premiumId)
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Document Name/Type',
            selector: row => row.document?row.document.docName:'',
            sortable: true,
        },
        {
            name: 'Business Class/Policy',
            selector: row => row.premium.customer.surname?`${row.premium.customer.surname} ${row.premium.customer.firstName}`:row.premium.customer.coporationName,
            sortable: true,
        },

        {
            name: 'Uploaded On',
            selector: row => (moment(row.createdAt).format("MMM Do , YYYY")),
            sortable: true,
        },

        {

            button: true,
            cell: (row) =>(<><a href ={row.documentPath} download><IconButton><CloudDownloadIcon/></IconButton> </a>
            <IconButton onClick={()=>deleteDocument(row)}><Delete className='text-danger'/></IconButton></>),
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => navigate('/documentation')}>
                        <PersonAdd /> Go To Documentation
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="Document Details"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>



        </Card>
    )
}

export default DocumentDetails
