

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Toolbar, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';

const AppNavbar = ({ children }) => {
    const location = useLocation().pathname.split('/')[1]
    return (
        <Box sx={{ display: 'flex' }}>

            <CssBaseline />
            <Sidebar id="sidebar" />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'rgb(248, 248, 255)', p: 3 }}
            >
                <Toolbar />
                
                <div className='col-xs-12 col-md-12 '>
                    
                    <h5>DASHBOARD <span className=' text-warning'>{location ? '/ ' + location.toLocaleUpperCase() : ""}</span>
                        <span className='float-end'>
                            <Typography variant='body1' style={{ color: "rgb(270, 149, 30)" }} sx={{fontWeight:"bold", display: { xs: "none", sm: "block" } }}>
                                Sonell Insurance Brokers
                            </Typography>
                        </span>
                    </h5>

                </div>
                <hr />
                <div className='col-md-12 col-xs-12 col-lg-12 pt-1'>
                    {children}
                </div>

            </Box>
        </Box>
    )
}

export default AppNavbar
