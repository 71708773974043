import React, { useCallback, useEffect, useMemo, useState } from 'react';


import { AccountBox, House, Inbox, PhoneEnabled, Work } from '@mui/icons-material'
import { Avatar, CardContent, CardHeader, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import MUICard from '@mui/material/Card'
import moment from 'moment'
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { publicRequest } from '../../requestMethods';
import { Card, Form } from 'react-bootstrap';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function FileUpload() {
   
    const [file, setFile] = useState([])
    const [message, setMessage] = useState('')
   
    const premium = useSelector(state => state.premium.currentPremium)
    const document=useSelector(state => state.premium.currentDocument);


    const onDrop = useCallback( (acceptedFile) => {

        setFile(acceptedFile[0]);

        const formData = new FormData()
        formData.append('file', acceptedFile[0])
        formData.append('premiumId', premium.id)
        formData.append('docName', document.docName)
        formData.append('documentId', document.id)
        formData.append('customerId', premium.customerId)

        submitForm(formData)

    }, [])
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
       

    } = useDropzone({
        acceptedFiles: 'image/*,*.pdf,*.doc',
        multiple: false,
        maxSize: "0.5mb",
        onDrop
    });
    //console.log(useDropzone)
const submitForm= async(formData)=>{
    try {
        const doc=    await publicRequest.post('upload/document', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        setMessage('file uploaded successfully')
    } catch (error) {
        console.log(error)
    }
   

           
}
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,


    ]

    );

    //console.log(useDropzone)
    return (
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {isDragReject ? <p className='text-danger'>File not accepted</p> :
                        <p>Drag and drop documents</p>}
                         <p className='text-success'> {message}</p>
                </div>
             



    );
}
export default FileUpload