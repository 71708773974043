import { createSlice } from '@reduxjs/toolkit'


const employeeSlice = createSlice(
    {
        name: "employee",
        initialState: {
            currentEmpoyee: [],
            isFetching: false,
            error: false,
            isloggedIn: false,

        },
        reducers: {
            actionStart: (state) => {
                state.isFetching = true;

            },
            findEmployeeSuccess: (state, action) => {
                state.isFetching = false;
                state.currentEmployee = action.payload;
                state.error = false;
                state.isloggedIn = true
            },
            findFailure: (state) => {
                state.isFetching = false;
                state.error = true
            },
      
           


        }



    })


export const {
    findEmployeeSuccess,
    findFailure,
    actionStart
} = employeeSlice.actions;
export default employeeSlice.reducer;