import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { Delete, Edit, PersonAdd } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import SearchInput from '../components/SearchInput';


const validationSchema = yup.object({
    premiumId: yup
        .string('Enter your username')
        .required('policy name is required'),
  
    amount: yup
        .number('A number is required')
        .required('Amount is require'),
   




});


const Commission = () => {


    const [showModal, setShowModal] = useState(false);
    const [customers, setCustomers] = useState([])
    const [customerId, setCustomerId] = useState('');
    const [premium, setPremium] = useState([])
    const [quotationAmount, setQuotationAmount] = useState(0)
    const [premiumBalance, setPremiumBalance] = useState(0)
    const [premiumId, setPremiumId] = useState('')
    const [custPremium, setCustPremium] = useState([])
    const [data, setData] = useState([])
    const [policies, setPolicies] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    


    // METHODES

    useEffect(() => {

        
        getAllCustomers();
        getAllCommission()

    }, [])

    useEffect(() => {
        getPremiumByCustomer()
    }, [customerId])
    const setNewCommissionForm = () => {
        formik.resetForm();
        setIsUpdate(false)
        setQuotationAmount(0)
        handleShow()
    }
    // FORMIK SET UPS
    const formik = useFormik({
        initialValues: {
            id: null,
            premiumId: '',
            amount: null,
           adminCharge: '',

        },

        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            const id = values.id;
            if (id) {
                // console.log(values)
                publicRequest.put('/commissions', values)
                    .then(res => {
                        handleClose()
                        getAllCommission()
                        notify('Premium updated Successfully')
                    })
            } else {
                publicRequest.post('/commissions/register', values)
                    .then(res => {
                        actions.resetForm()
                        handleClose()
                        getAllCommission()
                        notify('Premium created Successfully')

                    })
            }

        }
    })
    // Load Business Class
  
    // Load Customers
    const getAllCustomers = () => {
        publicRequest.get('customers')
            .then(res => setCustomers(res.data))
    }

   
    const getAllCommission = () => {
        publicRequest.get('commissions')
            .then(res => setData(res.data))
            .catch(err => {
                console.log(err)
            })
    }
    const findSelectedPremium = (e) => {
        const selectedPremium = custPremium.filter(item => {
            
            return item.id === Number(e.target.value)
        })
      
       
        setQuotationAmount((parseFloat(selectedPremium[0].amount)).toFixed(2))
       
        formik.handleChange(e)
    }


    const getPremiumByCustomer = () => {
        publicRequest.get(`premium/customer/${customerId}`)
            .then(res => setCustPremium(res.data))
            .catch(err => {
                console.log(err)
            })
    }

    const handleCustomerChange = (e) => {
        setCustomerId(e.target.value)
        formik.handleChange(e)
    }
    //UPDATE FORM SETUPS

    const setUpdateForm = (data) => {
        formik.setValues(data)
        setIsUpdate(true)
        setShowModal(true)
    }



    // DELETE USERS
    const deleteCommission = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/commissions/' + row.id)
                    .then(res => {
                        notify('Policy deleted successfully')
                        getAllCommission()
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index+1,
            sortable: true,
        },

        {
            name: 'Customer-Policy',
            selector: row => row.premium.customer && row.premium.customer.surname ? `${row.premium.customer.surname}, ${row.premium.customer.firstName} - [ ${row.premium.policy.policyName} ]` : `${row.premium.customer.coporationName} - [ ${row.premium.policy.policyName} ]` ,
            sortable: true,
            grow:2
        },

      
        {
            name: 'Quotation',
            selector: row => row.premium.amount,
            sortable: true,
        },
        {
            name: 'Commission',
            selector: row => row.premium.amount,
            sortable: true,
        },
        {
            name: 'Admin Charge',
            selector: row => row.adminCharge,
            sortable: true,
        },
      

        {
            name: 'Created At',
            selector: row => (moment(row.paymentDate).format("MMM Do , YYYY")),
            sortable: true,
        },

        {

            button: true,
            cell: (row) => <> <button className='btn btn-xs btn-outline-warning m-1' onClick={() => setUpdateForm(row)} ><Edit /></button>
                <button className='btn  btn-outline-danger' onClick={() => deleteCommission(row)}><Delete /></button>

            </>,
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => setNewCommissionForm()}>
                        <PersonAdd /> Add New
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        subHeader
                        subHeaderComponent={
                            <TextField variant="outlined"
                                  label="search....."
                                  placeholder='search'/>
                                        } 
                        pagination
                        title="Broker Commission/Admin Charge"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>


            <Modal show={showModal}
                onHide={handleClose}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdate ? "Update Policy" : "Create New"} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Customer">
                            <Form.Select
                                id="customerId"
                                name="customerId"
                                value={formik.values.customerId}
                                onChange={e => handleCustomerChange(e)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.customerId && Boolean(formik.errors.customerId)}

                            >
                                <option value=''>Select Customer-Premium</option>

                                {customers.map(customer =>
                                    (<option value={Number(customer.id)}>{`${customer.surname ? customer.surname : customer.coporationName} ${customer.firstName ? customer.firstName : ''}  -  (ID: ${customer.id})`}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Policy">
                            <Form.Select
                                id="premiumId"
                                name="premiumId"
                                value={formik.values.policyId}
                                onChange={e => findSelectedPremium(e)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.premiumId && Boolean(formik.errors.premiumId)}

                            >
                                <option value=''>Premium-Policy</option>

                                {custPremium.map(premium =>
                                    (<option value={Number(premium.id)}>{(premium.policy.policyName).toUpperCase()}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>
                        <h4 className='text-center text-bold text-danger' >Premium Quotation: {quotationAmount}</h4>
          
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Commission Amount"
                            type="number"
                            id="amount"
                            name="amount"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}

                        />
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Admin Charge"
                            type="number"
                            id="adminCharge"
                            name="adminCharge"
                            value={formik.values.adminCharge}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.adminCharge && Boolean(formik.errors.adminCharge)}
                            helperText={formik.touched.adminCharge && formik.errors.adminCharge}

                        />

                        

                  
                       
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-xs btn-outline-danger' onClick={handleClose}>
                        Close
                    </button>
                    <button type="submit" className='btn btn-xs btn-outline-warning' onClick={formik.handleSubmit} >
                        {isUpdate ? "Update" : "create"}
                    </button>
                </Modal.Footer>
            </Modal>

        </Card>
    )
}

export default Commission
