import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { Delete, Edit, PendingActions, PersonAdd } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';


const validationSchema = yup.object({
    customerType: yup
        .string('Select Customer Type')
        .required('username is required'),
  
    phoneNumber: yup
        .string('Please Enter PhoneNumber')
        .required('Phone number is required'),
    contactAddress: yup.string()
        .required('Phone number is required'),
    
   assetValue: yup.number('A Number is required here')
        .required('Asset value is required'),
   



});


const Customer = () => {

     const navigate=useNavigate()
    const [showModal, setShowModal] = useState(false);
    const [customerType,setCustomerType]=useState("INDIVIDUAL")
    const [data, setData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)

    const handleCustomerChange = ({ value }) => {
    setCustomerType(value)
}


    // METHODES

    useEffect(() => {

        getCustomers();
    }, [])

    const setNewCustomerForm = () => {
        formik.resetForm();
        setIsUpdate(false)
        handleShow()
    }
    // FORMIK SET UPS
    const formik = useFormik({
        initialValues: {
            id: null,
            customerType: '',
            coporationName: '',
            regNumber: '',
            dateOfInc: "",
            surname: '',
            firstName: '',
            midleName: '',
            propertyLocation: "",
            contactAddress: '',
            phoneNumber: '',
            age: '',
            gender: '',
            assetValue: '',
            email:''
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            console.log(values)
            const id = values.id;
            if (id) {
                // console.log(values)
                publicRequest.put('/customers', values)
                    .then(res => {
                        handleClose()
                        getCustomers()
                        notify(res.data)
                    })
                    .catch(err => console.log(err))
            } else {
                publicRequest.post('/customers/register', values)
                    .then(res => {
                        actions.resetForm()
                        handleClose()
                        getCustomers()
                        notify('Customers created Successfully')

                    })
                    .catch(err => console.log(err))
            }

        }
    })
    // Load Users
    const getCustomers = () => {
        publicRequest.get('customers')
            .then(res => setData(res.data))
            .catch(err=>console.log(err))
            
        
    }
    //UPDATE FORM SETUPS

    const setUpdateForm = (data) => {
        formik.setValues(data)
        setIsUpdate(true)
        setShowModal(true)
    }



    // DELETE USERS
    const deleteCustomer = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/customers/' + row.id)
                    .then(res => {
                        notify('Customer deleted successfully')
                        getCustomers()
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'CUST/ID',
            cell: (row, index) => row.id,
            sortable: true,
        },
        {
            name: 'ACCOUNT TYPE',
            selector: row => row.customerType,
            sortable: true,
        },
        {
            name: 'NAME',
            cell: row => (<Link to='/profile'> {row.customerType === "INDIVIDUAL" ? `${row.surname} ,${row.firstName} ` : row.coporationName}</Link>),
            sortable: true,
        },
        {
            name: 'EMAIL',
            selector: row => row.user? row.user.email:'',
            sortable: true,
        },
        {
            name: 'PHONE',
            selector: row => row.phoneNumber ,
            sortable: true,
        },
        {
            name: 'ASSET VALUE',
            selector: row => `N${parseFloat(row.assetValue).toFixed(2)}`,
            sortable: true,
        },
        {
            name: 'REG DATE',
            selector: row => (moment(row.createdAt).format("MMM Do , YYYY")),
            sortable: true,
        },
       
        {

            button: true,
            cell: (row) => <> <button className='btn btn-xs btn-outline-warning m-1' onClick={() => setUpdateForm(row)} ><Edit /></button>
                <button className='btn  btn-outline-danger' onClick={() => deleteCustomer(row)}><Delete /></button>

            </>,
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => setNewCustomerForm()}>
                        <PersonAdd /> Add New
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="Customers Information"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>


            <Modal show={showModal}
                onHide={handleClose}
                centered
                size="lg"
                className='mt-5'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdate ? "Update User" : "Create New"} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form  onSubmit={formik.handleSubmit}>

                        <FloatingLabel className="mb-3" controlId="floatingSelect" label="Customer Type">
                            <Form.Select
                                id="customerType"
                                name="customerType"
                                value={formik.values.customerType}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.customerType && Boolean(formik.errors.customerType)}
                                

                            >
                                <option>Select Customer Type</option>
                                <option value="INDIVIDUAL">INDIVIDUAL</option>
                                <option value="COPORATE">COPORATE</option>
                            </Form.Select>
                        </FloatingLabel>
                        <h5 className='text-center '>{formik.values.customerType}  ACCOUNT INFORMATION</h5>
                        <hr />
                        {formik.values.customerType === "INDIVIDUAL" && <div>
                         
                            <TextField variant="outlined"
                                fullWidth sx={{ paddingBottom: "20px" }}
                                label="Surname"
                                type="text"
                                id="surname"
                                name="surname"
                                value={formik.values.surname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.surname && Boolean(formik.errors.surname)}
                                helperText={formik.touched.surname && formik.errors.surname}

                            />

                            <TextField variant="outlined"
                                fullWidth sx={{ paddingBottom: "20px" }}
                                label="First Name"
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}

                            />
                            <TextField variant="outlined"
                                fullWidth sx={{ paddingBottom: "20px" }}
                                label="Middle Name"
                                type="text"
                                id="middleName"
                                name="middleName"
                                value={formik.values.middleName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                                helperText={formik.touched.middleName && formik.errors.middleName}

                            />
                            <TextField variant="outlined"
                                fullWidth sx={{ paddingBottom: "20px" }}
                                fullWidth
                                label="Age "
                                type="number"
                                id="age"
                                name="age"
                                value={formik.values.age}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.age && Boolean(formik.errors.age)}
                                helperText={formik.touched.age && formik.errors.age}

                            />
                        </div>
                        }
                        {formik.values.customerType === "COPORATE" && <div>
                            <TextField variant="outlined"
                                fullWidth sx={{ paddingBottom: "20px" }}
                                label="Coporation Name"
                                type="text"
                                id="coporationName"
                                name="coporationName"
                                value={formik.values.coporationName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.coporationName && Boolean(formik.errors.coporationName)}
                                helperText={formik.touched.coporationName && formik.errors.coporationName}

                            />

                            <TextField variant="outlined"
                                fullWidth sx={{ paddingBottom: "20px" }}
                                label="Registration Number"
                                type="text"
                                id="regNumber"
                                name="regNumber"
                                value={formik.values.regNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.regNumber && Boolean(formik.errors.regNumber)}
                                helperText={formik.touched.regNumber && formik.errors.regNumber}

                            />
                            <TextField variant="outlined"
                                fullWidth sx={{ paddingBottom: "20px" }}
                                label="Date Of Inc"
                                type="date"
                                id="dateOfInc"
                                name="dateOfInc"
                                value={formik.values.dateOfInc}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.dateOfInc && Boolean(formik.errors.dateOfInc)}
                                helperText={formik.touched.dateOfInc && formik.errors.dateOfInc}

                            />
                            
                        </div>
                        }
                        {/* General Infomation */}
                        <h5 className='text-center '>  GENERAL INFORMATION</h5>
                        <hr/>
                    {!isUpdate && <TextField variant="outlined"
                    sx={{ paddingBottom: "20px" }}
                        fullWidth
                        label="Email Address "
                        type="email"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}

                    />}
                        <TextField variant="outlined"
                            sx={{ paddingBottom: "20px" }}
                            fullWidth
                            label="Phone Number "
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}

                        />
                        <TextField variant="outlined"
                            sx={{ paddingBottom: "20px" }}
                            fullWidth
                            label="Contact Address "
                            type="text"
                            id="contactAddress"
                            name="contactAddress"
                            value={formik.values.contactAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.contactAddress && Boolean(formik.errors.contactAddress)}
                            helperText={formik.touched.contactAddress && formik.errors.contactAddress}

                        />
                        <TextField variant="outlined"
                            sx={{ paddingBottom: "20px" }}
                            fullWidth
                            label="Property Location "
                            type="text"
                            id="propertyLocation"
                            name="propertyLocation"
                            value={formik.values.propertyLocation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.propertyLocation && Boolean(formik.errors.propertyLocation)}
                            helperText={formik.touched.propertyLocation && formik.errors.propertyLocation}

                        />
                        <TextField variant="outlined"
                            sx={{ paddingBottom: "20px" }}
                            fullWidth
                            label="Asset Value "
                            type="number"
                            id="assetValue"
                            name="assetValue"
                            value={formik.values.assetValue}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.assetValue && Boolean(formik.errors.assetValue)}
                            helperText={formik.touched.assetValue && formik.errors.assetValue}

                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger' onClick={handleClose}>
                        Close
                    </Button>
                    <Button type='submit' className='btn-success' onClick={formik.handleSubmit}>
                        {isUpdate ? "Update" : "create"}
                    </Button>
                </Modal.Footer>
            </Modal>
           
        </Card>
    )
}

export default Customer
