import React, { useCallback, useEffect, useMemo, useState } from 'react';


import { AccountBox, House, Inbox, PhoneEnabled, Work } from '@mui/icons-material'
import { Avatar, CardContent, CardHeader, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import MUICard from '@mui/material/Card'
import moment from 'moment'
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { publicRequest } from '../requestMethods';
import { Card, Form } from 'react-bootstrap';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function FileUpload() {
   // console.log(id)
    const [file, setFile] = useState([])
    const user  = useSelector(state=>state.employee.currentEmployee)
    

    console.log("Id from upload", user.id)
    const onDrop =useCallback((acceptedFile,e) => {
        
           
        setFile(acceptedFile[0]);
        
        const formData = new FormData()
        formData.append('file', acceptedFile[0])
        formData.append('id', user.user.id)
        publicRequest.post('upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            
            .then(res => {
               // console.log(res.data)
            })

    },[])
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
      
    } = useDropzone({
        accept: 'image/*',
       multiple:false,
        maxSize: "0.5mb",
        onDrop
    });
    //console.log(useDropzone)
  
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
        
        
    ]
       
    );

    //console.log(useDropzone)
    return (
        <Form>

            <CardHeader
                title={user.firstName ? `${user.firstName} ${user.surname} ` : user.coporationName}
                avatar={<Avatar sizes='medium' src={user.user ? user.user.profileImage : '/img/user.jpg'}
                    sx={{ bgcolor: 'red', width: { xs: 50, sm: 100 }, height: { xs: 50, sm: 100 } }}>

                    UP
                </Avatar>}
                subheader={user.user ? user.user.role : "ADMIN"}
            />
            <Divider />

            <Card.Body>


            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragReject ?<p className='text-danger'>File not accepted</p>:
                    <p>Drag and drop profile image Or select image</p>}
            </div>
       



         <h5 className='text-center pt-2 text-warning'>Contact Info</h5>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <Inbox />
                                        </ListItemIcon>
                                        <ListItemText primary={user.user ? user.user.email:''} />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <PhoneEnabled />
                                        </ListItemIcon>
                                        <ListItemText primary={"+234 " + user.phoneNumber} />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <AccountBox />
                                        </ListItemIcon>
                                        <ListItemText primary={user.contactAddress} />
                                    </ListItemButton>
                                </ListItem>
                                
                        </List>
                        
                        <h5 className='text-center pt-2 text-warning'>Work Info</h5>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <Inbox />
                                    </ListItemIcon>
                                    <ListItemText primary={user.employeeNumber} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <Work />
                                    </ListItemIcon>
                                    <ListItemText primary={ user.designation} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <House />
                                    </ListItemIcon>
                                    <ListItemText primary={user.department} />
                                </ListItemButton>
                            </ListItem>

                        </List>
                       

                        
                        </Card.Body >
                        </Form>
    );
}
export default FileUpload