import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { Delete, Edit, PersonAdd } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods';
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { TextField } from '@mui/material';


const validationSchema = yup.object({
    docName: yup
        .string('Enter your username')
        .required('policy name is required'),

    businessClassId: yup
        .number('Select policy class')
        .required('Business class is required'),




});


const Documents = () => {


    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([])
    const [businessClass, setBusinessClass] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)




    // METHODES

    useEffect(() => {

        getAllClass();
        getAllDocuments()
    }, [])

    const setNewDocumentForm = () => {
        formik.resetForm();
        setIsUpdate(false)
        handleShow()
    }
    // FORMIK SET UPS
    const formik = useFormik({
        initialValues: {
            id: null,
            docName: '',
            businessClassId: null

        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            const id = values.id;
            if (id) {
                // console.log(values)
                publicRequest.put('/documents', values)
                    .then(res => {
                        handleClose()
                        getAllDocuments()
                        notify('Policy updated Successfully')
                    })
            } else {
                publicRequest.post('/documents/register', values)
                    .then(res => {
                        actions.resetForm()
                        handleClose()
                        getAllDocuments()
                        notify('Policy created Successfully')

                    })
            }

        }
    })
    // Load Users
    const getAllClass = () => {
        publicRequest.get('business_class')
            .then(res => setBusinessClass(res.data))
    }

    const getAllDocuments = () => {
        publicRequest.get('documents')
            .then(res => setData(res.data))
    }
    //UPDATE FORM SETUPS

    const setUpdateForm = (data) => {
        formik.setValues(data)
        setIsUpdate(true)
        setShowModal(true)
    }



    // DELETE USERS
    const deletePolicy = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/documents/' + row.id)
                    .then(res => {
                        notify('Document deleted successfully')
                        getAllDocuments()
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Document Type/Name',
            selector: row => row.docName,
            sortable: true,
        },
        {
            name: 'Business Class',
            selector: row => row.businessClass.businessType,
            sortable: true,
        },

        {
            name: 'Reg Date',
            selector: row => (moment(row.createdAt).format("MMM Do , YYYY")),
            sortable: true,
        },

        {

            button: true,
            cell: (row) => <> <button className='btn btn-xs btn-outline-warning m-1' onClick={() => setUpdateForm(row)} ><Edit /></button>
                <button className='btn  btn-outline-danger' onClick={() => deletePolicy(row)}><Delete /></button>

            </>,
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => setNewDocumentForm()}>
                        <PersonAdd /> Add New
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="Document List"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>


            <Modal show={showModal}
                onHide={handleClose}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdate ? "Update Policy" : "Create New"} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Business">
                            <Form.Select
                                id="businessClassId"
                                name="businessClassId"
                                value={formik.values.businessClassId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.businessClassId && Boolean(formik.errors.businessClassId)}

                            >
                                <option value=''>Select Business Class</option>

                                {businessClass.map(item =>
                                    (<option value={Number(item.id)}>{(item.businessType).toUpperCase()}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>

                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Document Type Or Name"
                            type="docName"
                            id="docName"
                            name="docName"
                            value={formik.values.docName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.docName && Boolean(formik.errors.docName)}
                            helperText={formik.touched.docName && formik.errors.docName}

                        />


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger' onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" className='btn-success' onClick={formik.handleSubmit} >
                        {isUpdate ? "Update" : "create"}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Card>
    )
}

export default Documents
