import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { Delete, Edit, PersonAdd } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { TextField } from '@mui/material';


const validationSchema = yup.object({
    policyId: yup
        .string('Enter your username')
        .required('policy name is required'),

    customerId: yup
        .string('this is a string')
        .required('customer is required'),
    amount: yup
        .number('A number is required')
        .required('Amount is require'),
    premiumPeriod: yup
        .string('Select policy class')
        .required('Validity period is required'),




});


const Premium = () => {


    const [showModal, setShowModal] = useState(false);
    const [customers, setCustomers] = useState([])
    const [data, setData] = useState([])
    const [policies, setPolicies] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [companies, setCompanies ]  =useState([])
    


    // METHODES

    useEffect(() => {

        getPolicies();
        getAllPremium();
        getAllCustomers();
        getAllCompanies();

    }, [])

    const setNewPremiumForm = () => {
        formik.resetForm();
        setIsUpdate(false)
        handleShow()
    }
    // FORMIK SET UPS
    const formik = useFormik({
        initialValues: {
            id: null,
            policyId: '',
            customerId: null,
            businessClassId: null,
            amount: null,
            startDate: '',
            premiumPeriod: '',
            endDate:'',
            policyNumber:'',
            renewalAmount: null,
            insuranceCompanyId: null
        },

        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            const id = values.id;
            if (id) {
                // console.log(values)
                publicRequest.put('/premium', values)
                    .then(res => {
                        handleClose()
                        getAllPremium()
                        notify('Premium updated Successfully')
                    })
            } else {
                publicRequest.post('/premium/register', values)
                    .then(res => {
                        actions.resetForm()
                        handleClose()
                        getAllPremium()
                        notify('Premium created Successfully')

                    })
            }

        }
    })
    // Load Business Class
    const getPolicies = () => {
        publicRequest.get('policy')
            .then(res => setPolicies(res.data))
    }

    const getAllCompanies = () => {
        publicRequest.get('company')
            .then(res => setCompanies(res.data))
    }
    // Load Customers
    const getAllCustomers = () => {
        publicRequest.get('customers')
            .then(res => setCustomers(res.data))
    }

    const getAllPremium = () => {
        publicRequest.get('premium')
            .then(res => setData(res.data))
    }
    //UPDATE FORM SETUPS

    const setUpdateForm = (data) => {
        formik.setValues(data)
        setIsUpdate(true)
        setShowModal(true)
    }



    // DELETE USERS
    const deletePremium = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/premium/' + row.id)
                    .then(res => {
                        notify('Policy deleted successfully')
                        getAllPremium()
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Customer Class',
            selector: (row, index) => row.customer.customerType,
            sortable: true,
        },
        {
            name: 'Customer',
            selector: row => row.customer.surname && row.customer.surname ? `${row.customer.surname}, ${row.customer.firstName}` : row.customer.coporationName,
            sortable: true,
            grow:2
        },
        {
            name: 'Policy',
            selector: row => row.policy.policyName,
            sortable: true,
        },
        {
            name: 'Policy Number',
            selector: row => row.policyNumber,
            sortable: true,
        },
        {
            name: 'Quotation Amount',
            selector: row => row.amount,
            sortable: true,
        },

        {
            name: 'Reg Date',
            selector: row => (moment(row.createdAt).format("MMM Do , YYYY")),
            sortable: true,
        },

        {

            button: true,
            cell: (row) => <> <button className='btn btn-xs btn-outline-warning m-1' onClick={() => setUpdateForm(row)} ><Edit /></button>
                <button className='btn  btn-outline-danger' onClick={() => deletePremium(row)}><Delete /></button>

            </>,
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => setNewPremiumForm()}>
                        <PersonAdd /> Add New
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="Premium List"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>


            <Modal show={showModal}
                onHide={handleClose}
                centered
                size="md"
                className='mt-5 pb-5'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdate ? "Update Policy" : "Create New"} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Customer">
                            <Form.Select
                                id="customerId"
                                name="customerId"
                                value={formik.values.customerId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.customerId && Boolean(formik.errors.customerId)}
                               
                            >
                                <option value=''>Select Customer</option>

                                {customers.map(customer =>
                                    (<option value={Number(customer.id)}>{`${customer.surname ? customer.surname : customer.coporationName} ${customer.firstName ? customer.firstName : ''}  -  (ID: ${customer.id})`}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Policy">
                            <Form.Select
                                id="policyId"
                                name="policyId"
                                value={formik.values.policyId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.policyId && Boolean(formik.errors.policyId)}
                                
                            >
                                <option value=''>Select Policy</option>

                                {policies.map(policy =>
                                    (<option value={Number(policy.id)}>{(policy.policyName).toUpperCase()}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Quotation Amount"
                            type="number"
                            id="amount"
                            name="amount"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}

                        />

                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Start Date"
                            type="date"
                            id="startDate"
                            name="startDate"
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                            helperText={formik.touched.startDate && formik.errors.startDate}

                        />
                              <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="End Date"
                            type="date"
                            id="endDate"
                            name="endDate"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                            helperText={formik.touched.endDate && formik.errors.endtDate}

                        />

                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Renewal Amount"
                            type="number"
                            id="renewalAmount"
                            name="renewalAmount"
                            value={formik.values.renewalAmount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.renewalAmount && Boolean(formik.errors.renewalAmount)}
                            helperText={formik.touched.renewalAmount && formik.errors.renewalAmount}

                        />

                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Premium Validity Period"
                            type="text"
                            id="premiumPeriod"
                            name="premiumPeriod"
                            value={formik.values.premiumPeriod}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.premiumPeriod && Boolean(formik.errors.premiumPeriod)}
                            helperText={formik.touched.premiumPeriod && formik.errors.premiumPeriod}

                        />

                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Insurance Company">
                            <Form.Select
                                id="insuranceCompanyId"
                                name="insuranceCompanyId"
                                value={formik.values.insuranceCompanyId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.insuranceCompanyId && Boolean(formik.errors.insuranceCompanyId)}
                                
                            >
                                <option value=''>Select Insurance Company</option>

                                {companies.map(company =>
                                    (<option key={company.id} value={company.id}>{company.name}</option>)
                                )}
                            </Form.Select>

                        </FloatingLabel>
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Policy Number From Insurance Company"
                            type="text"
                            id="policyNumber"
                            name="policyNumber"
                            value={formik.values.policyNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.policyNumber && Boolean(formik.errors.policyNumber)}
                            helperText={formik.touched.policyNumber && formik.errors.policyNumber}

                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger' onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" className='btn-success' onClick={formik.handleSubmit} >
                        {isUpdate ? "Update" : "create"}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Card>
    )
}

export default Premium
