import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { publicRequest, userRequest } from '../../requestMethods'
import moment from 'moment'
import { useSelector } from 'react-redux';


const PaymentDetails = () => {


    const [showModal, setShowModal] = useState(false);
    const [customers, setCustomers] = useState([])
    const [customerId, setCustomerId] = useState('');
    const [paymentInfo, setPaymentInfo] = useState([])
    const [quotationAmount, setQuotationAmount] = useState(0)
    const [custPremium, setCustPremium] = useState([])
    const [commission, setCommission] = useState([])
    const [commissionPay, setCommissionPay] = useState([])
    const [data, setData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const currentPremium=useSelector(state=>state.premium.currentPremium)

    



    // METHODES

    useEffect(() => {

        
       // getAllCustomers();
        getAllPayments();
        getCommission();

    }, [])
console.log('commission',commission)
    // useEffect(() => {
    //     getPremiumByCustomer()
    // }, [customerId])
 
    // FORMIK SET UPS

    // Load Business Class
  
    // Load Customers
    const getAllCustomers = () => {
        publicRequest.get('customers')
            .then(res => setCustomers(res.data))
    }

   
    const getAllPayments = () => {
        publicRequest.get('payment/premium/'+currentPremium.id)
            .then(res => {
                setData(res.data.payments);
                setPaymentInfo(res.data.totalPayment);
                console.log(res.data.totalPayment)
            }
                )
            .catch(err => {
                console.log(err)
            })
    }

const getCommission=()=>{
    publicRequest.get('commissions/premium/'+currentPremium.id)
            .then(res => {
                setCommission(res.data);
              
                
            //     payDetails.push(res.data.commissionPay)
              // console.log('kkk',res.data)
            //   // commissionPay.push(res.data.commissionPay)
            //     setCommissionPay(payDetails)
                
             
            }
                )
            .catch(err => {
                console.log(err)
            })
}


    const getPremiumByCustomer = () => {
        publicRequest.get(`premium/customer/${customerId}`)
            .then(res => setCustPremium(res.data))
            .catch(err => {
                console.log(err)
            })
    }

 
    //UPDATE FORM SETUPS

 



    // Modal Box Control

    // React Toaster
    const notify = (message) => toast.success(message);


    return (
        <Card >

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <h4 className='text-center text-uppercase' style={{ color:'navy' }}>Payment Details</h4>
                    <hr/>
                </div>
                <div className='col-md-12 col-xs-12'>
                  <table className='table table-borderless'>
                      <thead>
                          <tr>
                              <th  scope="col">S/Number</th>
                              <th  scope="col">Amount Paid</th>
                              <th  scope="col">Payment Date</th>
                          </tr>
                      </thead>
                      <tbody>
                          {
                              data.map((row,index)=>(
                                <tr>
                                <th >{index+1}</th>
                                 <td>N{(row.amount).toFixed(2)}</td>
                                 <td>{moment(row.paymentDate).format("MMM Do , YYYY")}</td>
   
                             </tr>
                              ))
                          }
                         
                          <tr>
                              
                              <th colSpan={3} style={{ color:'navy' }}>
                                 PAYMENT SUMMARY
                                 
                              </th>
                              
                              
                          </tr>
                          <tr>
                              <th colSpan={2}>Quotation Amount</th>
                              <th >N{(currentPremium.amount).toFixed(2)}</th>
                              
                          </tr>
                          <tr>
                             
                              <th colSpan={2}> Total Amount Paid</th>
                              <th >N{(paymentInfo._sum?paymentInfo._sum.amount:0.00).toFixed(2)}</th>
                             
                          </tr>
                          <tr className='p-5'>
                              
                           
                              <th colSpan={2}>Balance </th>
                              <th className='text-danger' > N{(currentPremium.amount-(paymentInfo._sum?paymentInfo._sum.amount:0.00)).toFixed(2)}</th>
                          </tr>
                      </tbody>

                  </table>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <h4 className='text-center text-uppercase' style={{ color:'navy' }}>Comission/Admin Charges </h4>
                    <hr/>
                </div>
                <div className='col-md-12 col-xs-12'>
                  <table className='table table-borderless'>
                      <thead>
                          <tr>
                              <th  scope="col">S/Number</th>
                              <th  scope="col">Comission</th>
                              <th  scope="col">Admin Charge</th>
                              <th  scope="col">Payment Date</th>
                          </tr>
                      </thead>
                      <tbody>
                            { 
                              commission.map((row,index)=>(
                                <tr>
                                <th key={index}>{index+1}</th>
                                      <td>N{(row.amount).toFixed(2)}</td>
                                      <td>N{(row.adminCharge).toFixed(2)}</td>
                                 <td>{moment(row.paymentDate).format("MMM Do , YYYY")}</td>
   
                             </tr>
                              ))
                          }
                         
                          <tr>
                              
                              <th colSpan={3} style={{ color:'navy' }}>
                                 PAYMENT SUMMARY
                                 
                              </th>
                              
                              
                          </tr>
                          <tr>
                              <th colSpan={2}>Quotation Amount</th>
                              <th >N{(currentPremium.amount).toFixed(2)}</th>
                              
                          </tr>
                          <tr>
                             
                              <th colSpan={2}> Total Amount Paid</th>
                              <th >N{(paymentInfo._sum?paymentInfo._sum.amount:0.00).toFixed(2)}</th>
                             
                          </tr>
                          <tr className='p-5'>
                              
                           
                              <th colSpan={2}>Balance </th>
                              <th className='text-danger' > N{(currentPremium.amount-(paymentInfo._sum?paymentInfo._sum.amount:0.00)).toFixed(2)}</th>
                          </tr>
                      </tbody>

                  </table>
                </div>







            </Card.Body>


        </Card>
    )
}

export default PaymentDetails
