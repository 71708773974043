import { createSlice } from '@reduxjs/toolkit'


const userSlice = createSlice(
    {
        name: "user",
        initialState: {
            currentUser: null,
            isFetching: false,
            error: false,
            isloggedIn: false,

        },
        reducers: {
            actionStart: (state) => {
                state.isFetching = true;

            },
            actionSuccess: (state, action) => {
                state.isFetching = false;
                state.currentUser = action.payload;
                state.error = false;
                state.isloggedIn = true
            },
            actionFailure: (state) => {
                state.isFetching = false;
                state.error = true
            },
            registerUser: (state, action) => {
                state.isFetching = false;
                state.currentUser = null;
                state.error = false;
                state.isloggedIn = false;
            },
            userLogout: (state, action) => {
                state.isFetching = false;
                state.currentUser = null;
                state.error = false;
                state.isloggedIn = false;
            }


        }



    })


export const {
    actionStart,
    actionSuccess,
    actionFailure,
    registerUser, userLogout
} = userSlice.actions;
export default userSlice.reducer;