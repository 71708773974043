import { Collapse, Divider, Drawer, List, ListItem, Typography, AppBar, Toolbar, IconButton, Avatar, Box, InputBase, Input, MenuItem } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { ExpandLess, ExpandMore, Settings, StarBorder, Dehaze } from '@mui/icons-material';

import { menueData,settingsData } from './menueData';
import { useLocation, useNavigate } from 'react-router-dom';
import { Overlay, Popover } from 'react-bootstrap';
import { Logout } from '../redux/userApi';
import { useDispatch, useSelector } from 'react-redux';



export const drawerWidth = 250;

const Sidebar = () => {

    const [open, setOpen] = useState('none');
    const [drop, setDrop] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role=useSelector(state=>state.user.currentUser.role)

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handlePopOver = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const handleLogout = (event) => {
        Logout(dispatch)
        setShow(!show);
        setTarget(event.target);
    }


    const handleClick = (item) => {
        item && navigate(item.path)
        if (open === 'block') {
            setOpen('none')

        } else {
            setOpen('block')

        }
        // console.log(open)
    }


    return (<>
        <AppBar
            position="fixed"
            sx={{
                width: { xs: "100vw", sm: `calc(100% - ${drawerWidth}px)` },
                ml: `${drawerWidth}px`,
                backgroundColor: "white",
                color: "white",
                flexDirection: "row",
                justifyContent: "space-between"
            }}
        >
            <Toolbar>
                <IconButton sx={{ marginRight: "20px", color: "navy",display:{ xs:"block",sm:"none"}}} onClick={() => handleClick()}>
                    <Dehaze />
                </IconButton>
                {/* <Typography variant="h6" noWrap component="div" sx={{ color:"rgb(255, 149, 27)", cursor: "pointer", display: { xs: "none", sm: "block" } }} >
                 SONELL INSURANCE
                </Typography> */}
                <Typography variant="h6" noWrap component="div" sx={{ color: "navy", cursor: "pointer", display: { sm: "none", xs: "block" } }} >
                    SILTD
                </Typography>
                <SearchIcon size="small" fontSize="inherit" sx={{ marginLeft: "20px", color: "navy", display: { xs: "none", sm: "block" } }} />
                <InputBase type="text" placeholder="search...."
                    fullWidth sx={{ width: { xs: '40%' }, display: { xs: "none", sm: "block" } }} />





            </Toolbar>
            <div style={{ display:"flex",justifyItems:"center", frlexDirection:"row",alignItems:"center" ,color:"navy"}}>
                <h6 className="text-center fw-bold text-uppercase fs-5">{role}</h6>
                <IconButton aria-label="cart">
                    <Badge badgeContent={4} color="error" sx={{ color: "red", marginRight: "10px" }}>
                        <NotificationAddIcon sx={{ color: "navy" }} />
                    </Badge>

                </IconButton>

                
                <div ref={ref}>
                    <IconButton
                        onClick={handlePopOver}
                        data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="Bottom popover"
                        sx={{ marginRight: "20px" }}
                        edge="end">
                        <Avatar sx={{ backgroundColor: "navy" }} src="/img/user.jpg" alt="User"></Avatar>
                    </IconButton>
                    

                    <Overlay
                        show={show}
                        target={target}
                        placement="bottom"
                        container={ref}
                        containerPadding={20}
                    >
                        <Popover id="popover-contained">
                           
                            <Popover.Body>
                              
                                    
                           
                                <ListItemButton onClick={handleLogout}>
                                   
                                    <ListItemText primary="logout" />
                                </ListItemButton>
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                </div>
            </div>

        </AppBar>
        <Drawer
            variant="permanent"
            anchor="left"

            sx={{
                display: { sm: 'block', xs: open },
                width: drawerWidth,
                flexShrink: 0,
                color: "wheat",
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    color: "white",
                    backgroundColor: "rgb(45, 50,55)",
                    fontSize: "light",

                },
            }}

        >



            <Toolbar >
                <img src="/img/sonell.png" alt="logo" width="50" height="50" />
                <h5 className="text-center fw-lighter fs-4"> S/BROKERS</h5>


            </Toolbar>
            <Divider />
            <Toolbar>

                <Avatar sx={{ cursor: "pointer", marginRight: "5px" }} src="/img/user.jpg" alt="User" />
                <center>
                    <h6 className="text-center text-warning fw-lighter fs-5"> &nbsp; Welcome !<br /> Mr Gift</h6>
                </center>

            </Toolbar>

            <Divider />

            <List>
                {
                    menueData.map(item => (
                        <ListItem key={item.text}
                            button
                            onClick={() => handleClick(item)}
                            sx={{ fontWeight: "lighter", fontStyle: "normal" }}
                        >

                            <ListItemIcon >{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} sx={{ fontWeight: "lighter" }} />
                        </ListItem>
                    ))
                }
                <ListItemButton onClick={() => setDrop(!drop)}>
                    <ListItemIcon>
                        <Settings sx={{ color: 'red' }} />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                    {drop ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={drop} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {settingsData.map(item => (
                            <ListItemButton key={item.path} sx={{ pl: 4 }} onClick={() => handleClick(item)}>
                                <ListItemIcon >
                                    {item.icon}
                                </ListItemIcon >
                                <ListItemText primary={item.text}/>
                            </ListItemButton>
                       ))}
                        
                    </List>
                </Collapse>
            </List>
        </Drawer>
    </>
    )

}

export default Sidebar
