import { PrintRounded } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import moment, { isMoment } from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap"
import { useNavigate, useParams } from 'react-router-dom';
import { publicRequest } from '../requestMethods';
import "./Reciept.css"

const Reciept = () => {


    const [payment, setPayment] = useState([])
    const { id } = useParams()
    
    useEffect(() => {
        publicRequest.get('payment/' + id)
            .then(res => {
            setPayment(res.data)
        })
        
     },[])

console.log(payment)
    const printReciept = () => {
        window.print()
    }
    return (
        <div >
            <div className='card'>
                <div className='card-body' id="section-to-print">
                    <table className='table table-borderless'>
                        <tbody>
                            <tr>
                                <td>
                                    <Avatar
                                        src="/img/sonell1.jpg"
                                        sx={{ width:'65px',height:'65px' }}
                                    />
                                </td>
                                <td>
                                    <Col>
                                        <h3 style={{ color:'goldenrod' }}>  SONELL INSURANCE BROKERS</h3>
                                        <h5>URL: https:sonellinsurancebroker.com</h5>
                                        <h5 className='text-danger'>E-MAIL: support@sonellbrocker.com</h5>
                                        <h6 >TEL: +234 8000000030,0700023244 </h6>


                                    </Col>
                                </td>
                                <td>Receipt No.
                                    <h5>{payment.recieptNo}</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  
                    <br /><br />
                    <h4 className='text-center'>PAYMENT RECIEPT</h4>
                    <hr/>
                    <Row>
                        <Col xs={6} sm={6}>
                            <table className='table table-borderless '>
                                <tbody>
                                    <tr>
                                        <th>
                                            Customer's Name
                                        </th>
                                            
                                        {payment.customer &&<td>
                                            {payment.customer.surname ? `${payment.customer.surname} ${payment.customer.firstName}` :payment.customer.coporationName} 
                                        </td>}
                                    </tr>
                                    <tr>
                                        <th>
                                            Business Class
                                        </th>

                                        {payment.customer && <td>
                                            {payment.businessClass.businessType}
                                        </td>}
                                    </tr>
                                    <tr>
                                        <th>
                                           Policy
                                        </th>

                                        {payment.customer && <td>
                                            {payment.policy.policyName}
                                        </td>}
                                    </tr>
                                    <tr>
                                        <th>
                                            Policy Number
                                        </th>

                                        {payment.customer && <td>
                                            {payment.premium.policyNumber}
                                        </td>}
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col xs={6} sm={6}>

                            <table className='table ms-2'>
                                <tbody>
                                    <tr>
                                        <th>
                                           Premium Quotation
                                        </th>

                                        {payment.customer && <td>
                                            {payment.premium.amount}
                                        </td>}
                                    </tr>
                                    <tr>
                                        <th>
                                           Amount Paid
                                        </th>

                                        {payment.customer && <td>
                                            {payment.amount}
                                        </td>}
                                    </tr>
                                    <tr>
                                        <th>
                                            Payment Date
                                        </th>

                                        {payment.customer && <td>
                                            {moment(payment.createdAt).format("MMM Do , YYYY")}
                                        </td>}
                                    </tr>
                                    
                                </tbody>
                            </table>
                            
                        </Col>
                        <Col>
                        </Col>
                   </Row>
                        
                        
               
                </div>
               
            </div>
            <button className="btn btn-warning btn-lg m-5 float-end" onClick={() => printReciept()}><PrintRounded /></button>
        </div>
    )
}

export default Reciept
