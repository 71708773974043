import { Avatar } from '@mui/material'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Calendar from '../components/Calendar'
import { publicRequest } from '../requestMethods'





const Home = () => {

    const [count, setCount] = useState({})
    
    useEffect(() => {
        publicRequest.get('auth/counts')
            .then(res => {
            setCount(res.data)
        }).catch(err=>console.log(err))
    },[])

    return (
        <div className="col-md-12 col-xs-12 col-lg-12">

            <Card variant="outlined" className="shadow ">

                <Card.Body className='row'>
                    <div className='col-md-3 col-xs-12 col'>
                        <Card className="shadow m-2 bg-danger text-white" >
                            <Card.Body className='text-center'>
                                <h4 className="card-title text-center">
                                    <Avatar sx={{ backgroundColor: "red" }}>U</Avatar>
                                    {count.user}
                                </h4>
                                <p className="card-text text-center">Registered Users </p>

                                <Link to="users" className="btn btn-danger">More Info</Link>
                            </Card.Body>
                        </Card>
                        <Card className="shadow m-2 bg-navy text-white" style={{ backgroundColor: "navy" }}>
                            <Card.Body className='text-center'>
                                <h4 className="card-title text-center">
                                    <Avatar sx={{ backgroundColor: "navy" }}>C</Avatar>
                                    {count.customer}
                                </h4>
                                <p className="card-text text-center">Registered Customers </p>

                                <Link to="customers" className="btn btn btn-xs" style={{ backgroundColor: "navy", color: 'white' }}>More Info</Link>
                            </Card.Body>
                        </Card>

                        <Card className="shadow m-2 bg-success text-white" >
                            <Card.Body className='text-center'>
                                <h4 className="card-title text-center">
                                    <Avatar sx={{ backgroundColor: "rgb(2, 110, 50);" }}>U</Avatar>
                                    {count.premium}
                                </h4>
                                <p className="card-text text-center">Under Writers </p>

                                <Link to="/underwriters" className="btn btn-success btn-xs">More Info</Link>
                            </Card.Body>
                        </Card>
                        <Card className="shadow m-2 bg-primary text-white" >
                            <Card.Body className='text-center'>
                                <h4 className="card-title text-center">
                                    <Avatar sx={{ backgroundColor: "blue" }}>E</Avatar>
                                    {count.employee}
                                </h4>
                                <p className="card-text text-center">Active Employees </p>

                                <Link to="/employees" className="btn btn-primary btn-xs">More Info</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-9 col-xs-12'>
                        <Card className="shadow m-2">

                            <Calendar />

                        </Card>
                    </div>
                </Card.Body>
            </Card>

        </div>
    )
}

export default Home
