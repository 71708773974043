import { Box, Card, CardContent, TextField,  FormControlLabel, Checkbox, Avatar, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Col, FloatingLabel, Form, Image, Row, Button,} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from "../redux/userApi";



const Login = () => {




    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const navigate = useNavigate();
    const [error, setError] = useState(false)
    const loginErr = user.error

    useEffect(() => {
        // console.log(user.isLoggedIn)
        if (user.isloggedIn) {
            navigate('/')

        }
    }, [user])


    // console.log(isLoggedIn.isloggedIn)
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
           await login(dispatch, { email, password })
            setError(user.error)
        }
        catch (err) {
            // setError(true)
        }

    }


    return (
        <Box sx={
            {
                display: 'flex',
                bgcolor: "grey",
                width: '100vw',
                height: '100vh',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center',
                flexDirection: 'column'
            }
        }>
            <Avatar src='/img/sonell1.jpg' sizes=""
                sx={{ width: 56, height: 56 }}
            />
            <h1 className='text-italic text-bold mb2 text-muted'> Sonell Insurance Brokers</h1>
            <h4 className='text-italic text-muted text-warning mb-3'>Login To Continue</h4>
            <Card elevation={4} sx={{
                width: {
                    xs: "90%",
                    sm: "50%",
                    md: '35%',
                    lg: '30%'
                },
                height: "47%"
            }}>

                <CardContent
                    sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>

                    <Box component="div" sx={{ margin: { xs: "5px", md: "20px" } }}>
                        <Form>
                            {error && <center><h6 className='text-center text-danger mb2-2'>
                                wrong credentials
                            </h6> </center>}
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email address"
                                className="mb-5"
                            >
                                <Form.Control className='form-control'
                                    type="email"
                                    placeholder="name@sonell.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                                <Form.Control className='form-control'
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FloatingLabel>

                            <Row className='mb-3'>
                                <Col><FormControlLabel control={<Checkbox />} label="Remember me" /></Col>
                                <Col><a href='#'> Forgot your password?</a></Col>
                            </Row>

                            <div class="d-grid gap-2">
                                <button className="btn btn-warning btn-lg"
                                    onClick={(e) => handleLogin(e)}>Button</button>
                             
                            </div>
                        </Form>

                        <h5 className='text-center mt-2' style={{ cursor: "pointer" }}>
                            Register New Account?
                        </h5>


                    </Box>

                </CardContent>
            </Card >
        </Box >

    )
}

export default Login
