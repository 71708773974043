import axios from "axios";

const BASE_URL = "http://66.29.149.84/api/";
let TOKEN = "";
const persistData = JSON.parse(localStorage.getItem("persist:root"));
if (persistData) {
    const currentUser = JSON.parse(persistData.user).currentUser
    TOKEN = currentUser ? currentUser.accessToken : "";

}


export const publicRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        token: `Bearer ${TOKEN}`
    }

});
export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        token: `Bearer ${TOKEN}`
    },
});

