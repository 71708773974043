import { publicRequest } from "../requestMethods";
import {
    findPremiumSuccess,
    findFailure,
    actionStart
} from "./premiumRedux";

export const findPremium = async (dispatch, premium,document) => {
    dispatch(actionStart());


    try {
      //  const premium = await publicRequest.get(`premium/${id}`)
        dispatch(findPremiumSuccess({premium,document}))
    } catch (err) {
        console.log(err)
        dispatch(findFailure())
    }

}