import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { ToastContainer, toast } from 'react-toastify';
import { Delete, Edit, PendingActions, PersonAdd } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';


const validationSchema = yup.object({
    surname: yup
        .string('This field is a String')
        .required('Surname is required'),
    firstName: yup
        .string('This field is a String')
        .required('Firstname is required'),
   gender: yup.string('This field is a String')
        .required('gender is required'),

    phoneNumber: yup.string('Please Enter PhoneNumber')
        .required('Phone number is required'),

       



});


const Employee = () => {

    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const[emailExist,setEmailExist]=useState(false)
    const [customerType, setCustomerType] = useState("INDIVIDUAL")
    const [data, setData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)

    const handleCustomerChange = ({ value }) => {
        setCustomerType(value)
    }


    // METHODES

    useEffect(() => {

        getEmployees();
    }, [])

    const setNewEmployeeForm = () => {
        formik.resetForm();
        setIsUpdate(false)
        handleShow()
    }
    // FORMIK SET UPS
    const formik = useFormik({
        initialValues: {
            id: null,
            surname: '',
            firstName: '',
            middleName: null,
            contactAddress: null,
            phoneNumber: '',
            dob: null,
            gender: null,
            email: '',
            salary: null,
            startDate: null,
            designation: '',
            department:'',
            role: 'BASIC_USER'
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            console.log(values)
            const id = values.id;
            if (id) {
                // console.log(values)
                publicRequest.put('/employees', values)
                    .then(res => {
                        handleClose()
                        getEmployees()
                        notify(res.data)
                    })
                    .catch(err => console.log(err))
            } else {
                publicRequest.post('/employees/register', values)
                    .then(res => {
                        if (res.data.error) {
                            setEmailExist(true);
                        } else {
                            setEmailExist(false);
                            actions.resetForm()
                            handleClose()
                            getEmployees()
                            notify('Employee created Successfully')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        setEmailExist(false);
                       // notify('Employee created Successfully')
                    })
            }

        }
    })
    // Load Users
    const getEmployees = () => {
        publicRequest.get('employees')
            .then(res => setData(res.data))
            .catch(err => console.log(err))


    }
    //UPDATE FORM SETUPS

    const setUpdateForm = (data) => {
        formik.setValues(data)
        setIsUpdate(true)
        setShowModal(true)
    }



    // DELETE USERS
    const deleteEmployee = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                publicRequest.delete('/employees/' + row.id)
                    .then(res => {
                        notify('Employee deleted successfully')
                        getEmployees()
                    })

            }
        })
    }


    // Modal Box Control
    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true);
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'E/N',
            cell: (row, index) => row.employeeNumber,
            sortable: true,
        },
        {
            name: 'Surname',
            cell: row => (<Link to={`/profile/${row.id}`}> {row.surname}</Link>),
            sortable: true,
        },
        {
            name: 'First Name',
            cell: row => (<Link to={`/profile/${row.id}`}> {row.firstName}</Link>),
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.user ? row.user.email : '',
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phoneNumber,
            sortable: true,
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
        },

        {
            name: 'Role',
            selector: row => row.user ? row.user.role : '',
            sortable: true,
        },

        {

            button: true,
            cell: (row) => (<> <button className='btn btn-xs btn-outline-warning m-1' onClick={() => setUpdateForm(row)} ><Edit /></button>
                <button className='btn  btn-outline-danger' onClick={() => deleteEmployee(row)}><Delete /></button>

            </>),
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

                <div className='col-md-12 col-xs-12'>
                    <button className='btn btn-outline-warning float-end mb-2 ' title='Add New' onClick={() => setNewEmployeeForm()}>
                        <PersonAdd /> Add New
                    </button>
                </div>
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="Employee Information"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>


            <Modal show={showModal}
                onHide={handleClose}
                centered
                size="md"
                className='mt-5'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isUpdate ? "Update Employee" : "Create New"} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                     
                       
                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="Surname"
                            type="text"
                            id="suname"
                            name="surname"
                            value={formik.values.surname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.surname && Boolean(formik.errors.surname)}
                            helperText={formik.touched.surname && formik.errors.surname}

                        />

                        <TextField variant="outlined"
                            fullWidth sx={{ paddingBottom: "20px" }}
                            label="First Name"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}

                        /> 
                        <FloatingLabel className="mb-3" controlId="floatingGender" label="Gender">
                            <Form.Select
                                id="gender"
                                name="gender"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.gender && Boolean(formik.errors.gender)}
                                

                            >
                                <option >Select Gender</option>
                                <option value="MALE">MALE</option>
                                <option value="FEMALE">FEMALE</option>
                                <option value="OTHERS">OTHERS</option>
                            </Form.Select>

                        </FloatingLabel>
                        {!isUpdate && <TextField variant="outlined"
                            sx={{ paddingBottom: "20px" }}
                            fullWidth
                            label="Email Address "
                            type="email"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}

                        />}
                        {emailExist&&<span className="text-danger">Email already exist</span>}
                        <TextField variant="outlined"
                            sx={{ paddingBottom: "20px" }}
                            fullWidth
                            label="Phone Number "
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}

                        />
                        
                        
                        {!isUpdate &&
                            <FloatingLabel className="mb-3" controlId="roleSelect" label="User Role">
                            <Form.Select
                                id="roleId"
                                name="roleId"
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.role && Boolean(formik.errors.role)}
                               

                            >
                                <option>Select employee role</option>
                                <option value="EMPLOYEE">EMPLOYEE</option>
                                <option value="BROCKER">BROCKER</option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="BASIC_USER">BASIC USER</option>
                            </Form.Select>
                        </FloatingLabel>}

                        <TextField variant="outlined"
                            sx={{ paddingBottom: "20px" }}
                            fullWidth
                            label="Designation"
                            type="text"
                            id="designation"
                            name="designation"
                            value={formik.values.designation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.designation && Boolean(formik.errors.designation)}
                            helperText={formik.touched.designation && formik.errors.designation}

                        />
                        <FloatingLabel className="mb-3" controlId="roleSelect" label="Department">
                            <Form.Select
                                id="deprtment"
                                name="deprtment"
                                value={formik.valuedeprtment}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touchedeprtment && Boolean(formik.errordeprtment)}
                                
                            >
                                <option>Select Department</option>
                                <option value="FINANCE">FINANCE</option>
                                <option value="TRANSPORT">TRANSPORT</option>
                                <option value="ADMINISTRATION">ADMINISTRATION</option>
                                <option value="HUMAN RESOURCE">HUMAN RESOURCE</option>
                                <option value="MARKETING">MARKETING</option>
                                <option value="GENERAL">GENERAL</option>
                                <option value="OTHERS">OTHERS</option>
                                </Form.Select>
                            </FloatingLabel>
                                <FloatingLabel className="mb-3" controlId="roleSelect" label="User Role">
                            <Form.Select
                                id="roleId"
                                name="roleId"
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.role && Boolean(formik.errors.role)}
                               

                            >
                                <option>Select employee role</option>
                                <option value="EMPLOYEE">EMPLOYEE</option>
                                <option value="BROCKER">BROCKER</option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="BASIC_USER">BASIC USER</option>
                            </Form.Select>
                        </FloatingLabel>
                          
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger' onClick={handleClose}>
                        Close
                    </Button>
                    <Button type='submit' className='btn-success' onClick={formik.handleSubmit} >
                        {isUpdate ? "Update" : "create"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer
            />
        </Card>
    )
}

export default Employee
