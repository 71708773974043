import { publicRequest } from "../requestMethods";
import {
    findEmployeeSuccess,
    findFailure,
    actionStart
} from "./employeeRedux";

export const findEmployee = async (dispatch, id) => {
    dispatch(actionStart());


    try {
        const user = await publicRequest.get(`employees/${id}`)
        dispatch(findEmployeeSuccess(user.data))
    } catch (err) {
        console.log(err)
        dispatch(findFailure())
    }

}

// export const Register = (dispatch, data) => {
//     dispatch(actionStart)
//     publicRequest.post('/auth/register', data)
//         .then(res => {
//             dispatch(registerUser(res.data))
//         })
// }
// export const Logout = (dispatch) => {
//     dispatch(userLogout())
// }