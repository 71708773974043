import { Delete, Edit } from '@mui/icons-material'
import moment from 'moment'
import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react'
import { Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import FileUpload from '../components/underwriters/DocumentFileUpload'
import { publicRequest } from '../requestMethods'
import { findPremium } from '../redux/premiumApi';
import { useDispatch } from 'react-redux';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Documentation = () => {

  const navigate=useNavigate()

    const [premium, setPremium] = useState([])
    const [selectedPremium, setSelectedPremium] = useState({})
    const [documents, setDocuments] = useState([])
    const dispatch=useDispatch()
    const [data, setData] = useState([])
    const [docId,setDocId]=useState(null)
    const [reqDocument, setReqDocument] = useState({})
    
   



    useEffect(() => {
        getAllDocumentations()
        publicRequest.get('premium')
            .then(res => setPremium(res.data))
    },[])


    const handleSelectedPremium = (e) => {
        const selPremium = premium.filter(item => {
           return item.id === Number(e.target.value)
        })
        setSelectedPremium(selPremium[0])
        const busClassId = selPremium[0].policy.businessClassId

        publicRequest.get('documents/business/' + busClassId).
            then(res => {
            setDocuments(res.data)
        })

    }
    const getAllDocumentations = () => {
        publicRequest.get('documentations' ).
            then(res => {
                setData(res.data)
            }) 
    }
    const handleDocument = (e) => {
        //setDocId(item.id === e.target.value)
        const document = documents.filter(item => item.id === Number(e.target.value))
        setReqDocument(document[0]);
         findPremium(dispatch,selectedPremium,document[0])
        
    }
    const deleteDocument = (row) => {
        
    }
    const setUpdateForm= (row) => {

    }



    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Customer',
            selector: row => row.premium.customer.surname ? `${row.premium.customer.surname} 
            ${row.premium.customer.firstName}-(${row.premium.customer.id})-${row.premium.policy.policyName}`
                : `${row.premium.customer.coporationName}-(${row.premium.customer.id})-${row.premium.policy.policyName}`,
            sortable: true,
        },
        {
            name: 'Number of Documents',
          
            selector: row => (<Avatar onClick={()=>navigate(`/details/${row.premiumId}`)}
            sx={{ backgroundColor:'green', color:'whitesmoke',cursor:'pointer' }}>
                 {row.count}</Avatar> ),
            sortable: true,
           
            grow:2
        },

        

        

    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };

    return (
        <Card className='shadow'>
            <Row>
                <Col xs={12} md={12}>
                    <Card.Body>
                     
                            <FloatingLabel className="mb-3" controlId="floatingGender" label="Customer/Business Class">
                                <Form.Select
                                    id="premiumId"
                                    name="premiumId"
                                    value={selectedPremium.id}
                                    onChange={handleSelectedPremium}

                                >
                                    <option value=''>Select Business Class</option>

                                    {premium.map(premium =>
                                        (<option value={Number(premium.id)}>{`${premium.customer.surname ? premium.customer.surname : premium.customer.coporationName} ${premium.customer.firstName ? premium.customer.firstName : ''}  -  (ID: ${premium.customer.id})-${premium.policy.policyName}`}</option>)
                                    )}
                                </Form.Select>

                            </FloatingLabel>
                            

                            <FloatingLabel className="mb-3" controlId="floatingGender" label="Documents">
                                <Form.Select
                                    id="docId"
                                    name="docId"
                                    value={docId}
                                    onChange={(e) => handleDocument(e)}

                                >
                                    <option >Select Document</option>

                                    {documents.map(document =>
                                        (<option value={Number(document.id)}>{document.docName}</option>)
                                    )}
                                </Form.Select>

                            </FloatingLabel>

                       
                        <FileUpload />
                    </Card.Body>
                </Col>
                <Col xs={12} md={12}>
                    <Card className='shadow'>
                        <Container>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        actions="Actions"
                        title="Documentation List"

                       
                    />
                    </Container>
                    </Card>
                    
                </Col>
            </Row>
        </Card>
    )
}

export default Documentation
