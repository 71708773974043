import { Button, Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react"
import { toast } from 'react-toastify';
import { Delete, Edit, PersonAdd, RemoveRedEye } from '@mui/icons-material';
import { publicRequest, userRequest } from '../requestMethods'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { findPremium } from '../redux/premiumApi';


const validationSchema = yup.object({
    premiumId: yup
        .string('Enter your username')
        .required('policy name is required'),

    customerId: yup
        .string('this is a string')
        .required('customer is required'),
    amount: yup
        .number('A number is required')
        .required('Amount is require'),
    paymentDate: yup
        .string('Select policy class')
        .required('Validity period is required'),




});


const Underwriter = () => {


 
    const [data, setData] = useState([])
    const dispatch=useDispatch()
    const navigate=useNavigate()
    



    // METHODES

    useEffect(() => {


        getAllPayments()

    }, [])

 
  
    // FORMIK SET UPS
   
    // Load Business Class

    // Load Customers
  

    const getAllPayments = () => {
        publicRequest.get('premium')
            .then(res => setData(res.data))
            .catch(err => {
                console.log(err)
            })
    }
    
    
    const persistPremium = (premium) => {
        findPremium(dispatch, premium, [])
        .then(()=>navigate('/underwriterForm'))
}


    // DELETE USERS
    

    // Modal Box Control
   
    // React Toaster
    const notify = (message) => toast.success(message);


    // USERS TABLE SETUPS

    const conditionalRowStyles = [
        {
            when: row => row.role === "EMPLOYEE",
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',

                }

            }
        }]


    const columns = [
        {
            name: 'S/No',
            cell: (row, index) => index+1,
            sortable: true,
            grow:0.5
        },

        {
            name: 'Customer/Client',
            selector: row => row.customer && row.customer.surname ? `${row.customer.surname}, ${row.customer.firstName}` : row.customer.coporationName,
            sortable: true,
            grow:2
        },

        {
            name: 'Class Of Business',
            selector: row => row.businessClass.businessType,
            sortable: true,
        },
        {
            name: 'Policy Number',
            selector: row => row.policyNumber,
            sortable: true,
        },
        {
            name: 'PM. Quotaton',
            selector: row => row.amount? (row.amount).toFixed():'0.00',
            sortable: true,
        },
        {
            name: 'Amount Paid',
            selector: row => row.paymentTotal? (row.paymentTotal).toFixed(2):'0.00',
            sortable: true,
        },

        {
            name: 'Balance',
            selector: row => row.amount && row.paymentTotal ? (row.amount - row.paymentTotal).toFixed(2) : row.amount,
            sortable: true,
        },

        {

           
            cell: (row) => <> <button disabled={!row.paymentTotal} className='btn btn-xs btn-outline-warning m-1' title="view Details" onClick={() => persistPremium(row)} ><RemoveRedEye size="md"/></button>
                

            </>,
            allowOverflow: true,

        },

    ];



    const paginationComponentOptions = {
        rowsPerPageText: 'Perpage',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
        perPage: 20
    };
    return (
        <Card className='shadow '>

            <Card.Body >

              
                <div className='col-md-12 col-xs-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        subHeader
                        subHeaderComponent={
                            <TextField variant="outlined"
                                label="search..."
                                placeholder='Policy Number' />
                        } 
                        title="Under Writers List"
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>

            </Card.Body>

        </Card>
    )
}

export default Underwriter
