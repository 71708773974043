import React from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import { AttachMoney, ExpandLess, ExpandMore, Settings, StarBorder, Dehaze, Policy, Business, MonetizationOn, Payment, PendingActions, DocumentScanner, Money, DocumentScannerTwoTone } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { ownerDocument } from "@mui/material";

export const menueData = [
    {
        text: "Dashboard",
        icon: <DashboardIcon sx={{ color: "gold" }} />,
        path: "/dashboard",

    },
    {
        text: "Users",
        icon: <PeopleIcon sx={{ color: "white" }} />,
        path: "/users",

    },
    {
        text: "Customers",
        icon: <BookmarkAddIcon sx={{ color: "white" }} />,
        path: "/customers",

    },
    {
        text: "Employees",
        icon: <AttachMoney sx={{ color: "white" }} />,
        path: "/employees",

    },
  
    {
        text: "Business Class",
        icon: <Business sx={{ color: "white" }} />,
        path: "/classes",

    },
    {
        text: "Policy",
        icon: <Policy sx={{ color: "white" }} />,
        path: "/policy",

    },
    {
        text: "Premium",
        icon: <MonetizationOn sx={{ color: "white" }} />,
        path: "/premium",

    },
    {
        text: "Payments",
        icon: <Payment sx={{ color: "white" }} />,
        path: "/payments",

    },
    {
        text: "Documentation",
        icon: <DocumentScannerTwoTone sx={{ color: "white" }} />,
        path: "/documentation",

    },
    {
        text: "Underwriters",
        icon: <PendingActions sx={{ color: "white" }} />,
        path: "/underwriters",

    },
    

];

export const settingsData = [
    {
        text: "Insurance Companies",
        icon: <HistoryIcon sx={{ color: "white" }} />,
        path: "/company",

    },
    {
        text: "Documents",
        icon: <DocumentScanner sx={{ color: "white" }} />,
        path: "/documents",

    },
    {
        text: "Broker's Commission",
        icon: <Money sx={{ color: "white" }} />,
        path: "/commissions",

    },
    {
        text: "Commission Payment",
        icon: <Money sx={{ color: "white" }} />,
        path: "/commissions-payment",

    },
]