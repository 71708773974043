

import { useEffect, useState } from 'react'
import { Card, Col, Figure, Row, Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from '../components/FileUpload'
import EmployeeProfile from '../components/Profile'
import { publicRequest } from '../requestMethods'
import {findEmployee} from '../redux/employeeApi'

const Profile = () => {

    
    const dispatch = useDispatch()
    const [user, setUser]=useState([])
    const { id } = useParams();
   // console.log(id)
    useEffect( async() => {
        console.log('running')
        try {
            await findEmployee(dispatch, id )
            handleUser()
        }
        catch (err) {
            console.log(err)
        }
    }, [])
    const employee = useSelector(state => state.employee.currentEmployee)
    const handleUser=()=>setUser(employee)
    return (
        <Card className='shadow row'>
            <Row md={12} xs={12}>
                <div className="col-md-4 col-xs-12">
                    
                        
                        <FileUpload/>
                           
                    
                </div>
                <div className="col-md-8 col-xs-12">
                    
                    <Card className='shadow '>
                      
                    <Card.Body >
                        <Tabs defaultActiveKey="Personal" id="uncontrolled-tab-example" className="mb-3 btn btn-warning ">
                                <Tab eventKey="up" title="Update Center" disabled>
                                    
                                </Tab>
                                
                               
                                <Tab eventKey="Personal" title="Personal Info">
                                <EmployeeProfile />
                            </Tab>
                            <Tab eventKey="job" title="Official Info">

                            </Tab>
                            <Tab eventKey="security" title="Security" >

                            </Tab>
                        </Tabs>
                        </Card.Body>
                    </Card>
                </div>


            </Row>

        </Card>
    )
}

export default Profile
