

import AppNavbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Home from './pages/Home';
import User from './pages/User';
import Customer from './pages/Customer';
import Employee from './pages/Employee';
import BusinessClass from './pages/BusinessClass';
import Policy from './pages/Policy';
import Premium from './pages/Premium';
import Profile from './pages/Profile';
import Payment  from './pages/Payment';
import Underwriter from './pages/Underwriter';
import UnderwriterProcess from './components/UnderwriterProcess';
import Documents from './pages/Documents';
import Documentation from './pages/Documentation';
import DocumentDetails from './pages/DocumentDetails';
import Company from './pages/Company';
import Commission from './pages/Commission';
import CommissionPayment from './pages/CommissionPayment';
import Login from './pages/Login';
import { useSelector } from 'react-redux';
import Reciept from './pages/Reciept';
function App() {

  const { isloggedIn } = useSelector(state => state.user)
  const user = useSelector(state => state.user.currentUser)
  return (
    <Router>

      {user ?
        < AppNavbar >

          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" exact element={< Home />} />
            <Route path="/users" exact element={<User />} />
            <Route path="/customers" exact element={<Customer />} />
            <Route path="/employees" exact element={<Employee />} />
            <Route path="/classes" exact element={<BusinessClass />} />
            <Route path="/policy" exact element={<Policy />} />
            <Route path="/premium" exact element={<Premium />} />
            <Route path="/profile/:id" exact element={<Profile />} />
            <Route path="/payments/" exact element={<Payment />} />
            <Route path="/underwriters/" exact element={<Underwriter />} />
            <Route path="/documents" exact element={<Documents />} />
            <Route path="/details/:premiumId" exact element={<DocumentDetails />} />
            <Route path="/documentation" exact element={<Documentation />} />
            <Route path="/company" exact element={<Company />} />
            <Route path="/commissions" exact element={<Commission />} />
            <Route path="/commissions-payment" exact element={<CommissionPayment />} />
            <Route path="/underwriterForm" exact element={<UnderwriterProcess />} />
            <Route path="/reciept/:id" exact element={<Reciept />} />

          
          
          </Routes>
        </AppNavbar>
        :
        <Routes>
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="/login" exact element={<Login />} />
      </Routes>
      }
<ToastContainer/>
    </Router>
  );
}

export default App;
