import moment from "moment"
import { Card, Row, Col } from "react-bootstrap"
import { useSelector } from "react-redux"


const Summary = () => {


    const premium = useSelector(state => state.premium.currentPremium)


    // console.log('pppp',premium)


    return (
        <Card>

            <Card.Body>
                <h5 style={{ color: "goldenrod" }} className=" mt-3"> Ref: {premium.policyNumber } </h5>


                <Row>
                    <Col className="text-center ">
                        <h4>
                            {premium.customer.surname ? `${premium.customer.surname}, ${premium.customer.firstName}` : premium.customer.coporationName}
                        </h4>
                        <h5>
                            {premium.customer.contactAddress ? premium.customer.contactAddress : premium.customer.propertyLocation}
                        </h5>
                        <h5>
                            {premium.customer.user.email} &nbsp;&nbsp; {premium.customer.phoneNumber}
                        </h5>
                    </Col>

                </Row>
                <Row xs={12} md={12}>
                    <h4 className="m-5">Business Class Details</h4>

                </Row>
                <Row className=" table-responsive" xs={12} md={12}>
                    <Col>
                        <table className="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th>Business Class</th>
                                    <th>Policy Type</th>
                                    <th>Insurance Company</th>
                                    <th>Policy Number</th>
                                    <th>Premium Quotation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {premium.businessClass.businessType}
                                    </td>
                                    <td>
                                        {premium.policy.policyName}
                                    </td>
                                    <td>
                                        {premium.insuranceCompany.name}
                                    </td>
                                    <td>
                                        {premium.policyNumber}
                                    </td>
                                    <td>
                                        {(premium.amount).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
               

                <Row>
                    
                    <Col xs={12} md={6}>
                        <h4 className="m-5">Payment Details</h4>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Paymet Date</th>

                                </tr>
                            </thead>
                            <tbody>
                                {premium.Payment.map(pay => (
                                    <tr key={pay.id}>
                                        <td>{(pay.amount).toFixed(2)}</td>
                                        <td>{moment(pay.paymentDate).format("MMM Do, YYYY")}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th>
                                        Premium
                                    </th>
                                    <td>{(premium.amount).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Total Amount Paid
                                    </th>
                                    <td>{(premium.paymentTotal).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Balance
                                    </th>
                                    <td>{(premium.amount - premium.paymentTotal).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col xs={12} md={6}>
                        <h4 className="m-5">Commission/Admin Fee</h4>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Paymet Date</th>

                                </tr>
                            </thead>
                            <tbody>
                                {premium.BrokerCommissionPayment.map(pay => (
                                    <tr key={pay.id}>
                                        <td>N{(pay.amount).toFixed(2)}</td>
                                        <td>{moment(pay.paymentDate).format("MMM Do, YYYY")}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th>
                                       Commission
                                    </th>
                                    <td>{(premium.BrockerCommission[0].amount).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Total Amount Paid
                                    </th>
                                    <td>{(premium.commissionTotal).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Balance
                                    </th>
                                    <td>{(premium.BrockerCommission[0].amount - premium.commissionTotal).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <h4 className="m-5">Documentation</h4>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Document</th>
                                    <th>Uploaded On</th>

                                </tr>
                            </thead>
                            <tbody>
                                {premium.DocumentInfo.map(doc => (
                                    <tr key={doc.id}>
                                        <td>{doc.document.docName}</td>
                                        <td>{moment(doc.createdAt).format("MMM Do, YYYY")}</td>
                                    </tr>
                                ))}
                               
                            </tbody>
                        </table>
                        <Col md={6} xs={12}></Col>
                    </Col>

                </Row>
            </Card.Body>




        </Card>
    )
}

export default Summary
